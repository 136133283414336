import Head from 'next/head';
import { useState } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Components
import SpinnerButton from '@/components/SpinnerButton';
import ButtonLoginGoogle from '@/components/atoms/ButtonLoginGoogle';

export default function Index({ organization }) {
  const { t } = useTranslation(['common', 'formvalid', 'errors', 'marketing']);
  const router = useRouter();
  const [loading, setLoading] = useState(false); // Indicates Google Script loaded for Login

  // Start: form validation setup
  const schemaValidation = yup.object().shape({
    email: yup
      .string()
      .email(t('formvalid:yup_email_valid'))
      .required(t('formvalid:yup_required_field')),
    password: yup
      .string()
      .required(t('formvalid:yup_required_field'))
      .min(5, `${t('formvalid:yup_min')} 5 ${t('formvalid:yup_characters')}`)
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaValidation)
  });
  // End: form validation setup

  /**
   * [Form submit]
   *
   * @param   {[Object]}  data  [Form data object]
   *
   */
  const formSubmit = async (data) => {
    const { email, password } = data;
    // Set loading
    setLoading(true);

    axios
      .post('/login', {
        email,
        password,
        organization: organization ? organization.id : ''
      })
      .then(function (response) {
        if (response.data.result === 1) {
          // All ok, redirect
          router.push('/panel');
        } else {
          // Error with login
          toast.error(t(`errors:${response.data.err_code}`));
        }

        // Hide loading
        setLoading(false);
      })
      .catch(function () {
        // Error: fetching data
        toast.error(t('error_fetching_data'));

        // Hide loading
        setLoading(false);
      });
  };

  const loginLOGO = organization
    ? organization.logo_url
    : '/images/irysTeams.png';

  return (
    <GoogleOAuthProvider clientId="1045139183283-qr47nd013fq15olcdd7nupb5q4thttkc.apps.googleusercontent.com">
      <Head>
        <title>{t('marketing:title')}</title>
        <meta name="description" content={t('marketing:title_slogan_1')} />
      </Head>

      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Link href="/" legacyBehavior>
              <Image
                src={loginLOGO}
                height={50}
                width={50}
                className="object-contain h-20 w-20 cursor-pointer"
              />
            </Link>
          </div>

          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t('sign_in_title')}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(formSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  {t('email')}
                </label>
                <div className="mt-1">
                  <input
                    {...register('email')}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-militar-500 focus:border-militar-500 sm:text-sm"
                  />
                </div>
                <p>{errors?.email?.message}</p>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700">
                  {t('password')}
                </label>
                <div className="mt-1">
                  <input
                    {...register('password')}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-militar-500 focus:border-militar-500 sm:text-sm"
                  />
                </div>
                <p>{errors.password?.message}</p>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link
                    href="/forgot-password"
                    className="font-medium text-militar-600 hover:text-militar-500"
                    legacyBehavior>
                    {t('password_forgot')}
                  </Link>
                </div>
              </div>

              <div>
                <button
                  id="btnLogin"
                  disabled={loading}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-militar-600 hover:bg-militar-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-militar-500 disabled:opacity-50">
                  {loading && <SpinnerButton />}
                  {t('sign_in')}
                </button>
              </div>
            </form>

            {/* START: Social login (Disable for "CUSTOM SUBDOMAINS") */}
            {!organization && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      {t('sign_in_continue_with')}
                    </span>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1">
                  <ButtonLoginGoogle organization={organization} />
                </div>
              </div>
            )}

            {/* END: Social login */}
          </div>
        </div>
        <ToastContainer />

        {/* <Script
        id="google-gsi"
        src="https://accounts.google.com/gsi/client"
        onLoad={() => {
          setGSILoaded(true);
        }}
      /> */}
      </div>
    </GoogleOAuthProvider>
  );
}

// Get locale and validate subdomains
export async function getServerSideProps({ req, locale }) {
  let objParams = {}; // Extra params (used to redirect if required)
  let objExtraProps = {}; // Used to pass extra props

  if (req) {
    const currentHost = req.headers.host;
    if (currentHost && typeof currentHost === 'string') {
      const arrSub = currentHost.split('.');

      if (arrSub.length === 3) {
        // Validate subdomain
        // Valdiate subdomain if required
        const protocol =
          process.env.NODE_ENV === 'production' ? 'https://' : 'http://';

        const host = req.headers.host; // will give you localhost:3000

        const resSub = await axios.get(
          `${protocol}${host}/subdomain/${arrSub[0]}`
        );
        if (resSub.data.result === 1) {
          // All ok, subdomain exist, add params
          objExtraProps = { organization: resSub.data.organization };
        } else {
          // Error: the subdomain doesn't exist
          // Error: redirect to main page
          objParams = {
            redirect: {
              permanent: false,
              destination: process.env.BASE_URL
            }
          };
        }
      }
    }
  }

  return {
    ...objParams,
    props: {
      ...objExtraProps,
      ...(await serverSideTranslations(locale, [
        'common',
        'formvalid',
        'errors',
        'marketing'
      ]))
    }
  };
}
