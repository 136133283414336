import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

// Lib
import { loginWithGoogle } from '../../lib/api';

// Components
import SpinnerButton from '../SpinnerButton';

export default function ButtonLoginGoogle({ organization }) {
  const { t } = useTranslation(['common', 'errors']);
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(true);

      // Try to login with google
      loginWithGoogle(tokenResponse.access_token, organization)
        .then((resL) => {
          if (resL.data.result === 1) {
            // All ok, redirect
            router.push('/panel');
          } else {
            // Error with login
            toast.error(t(`errors:${resL.data.err_code}`));
          }

          setLoading(false); // Hide loading
        })
        .catch(() => {
          setLoading(false); // Hide loading

          toast.error(t('errors:ERR_GENERIC_REQUEST'));
        });
      // console.log(tokenResponse)
    }
  });

  return (
    <div>
      <button
        className="w-full inline-flex justify-center py-2 px-4 border border-blue-300 rounded-md shadow-sm bg-blue-600 text-sm font-medium  hover:bg-blue-500 text-white disabled:opacity-50"
        disabled={loading}
        onClick={() => login()}>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
            clipRule="evenodd"
          />
        </svg>
        <span className="ml-2 mr-2">Google</span>

        {loading && (
          <span>
            <SpinnerButton />
          </span>
        )}
      </button>
    </div>
  );
}
